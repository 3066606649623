var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { disabled: _vm.disableForm },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap flex-column align-content-start text-subtitle-1",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-row",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-center mx-3",
                    attrs: {
                      label: "Config Name",
                      rules: [
                        _vm.rules.required,
                        _vm.rules.no_spaces,
                        _vm.rules.minMaxLength(3, 42),
                      ],
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _vm.contribTypeOfGroup == _vm.CONTRIB_TYPES.PERCENT
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "Contrib Ratio",
                          suffix: "%",
                          type: "number",
                          step: "0.1",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.minMaxValue(0, 100),
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c("tooltip", {
                                    attrs: {
                                      iconSize: "18px",
                                      text: _vm.tooltips.contribRatio,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3675255977
                        ),
                        model: {
                          value: _vm.form.contrib_ratio,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contrib_ratio", _vm._n($$v))
                          },
                          expression: "form.contrib_ratio",
                        },
                      })
                    : _vm._e(),
                  _vm.contribTypeOfGroup == _vm.CONTRIB_TYPES.BET
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "Contrib Bet Min",
                          type: "number",
                          suffix: "in credits",
                          rules: [_vm.rules.required, _vm.rules.nonNegative],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c("tooltip", {
                                    attrs: {
                                      text: _vm.tooltips.contribBetRange,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2190931805
                        ),
                        model: {
                          value: _vm.contribBetMin,
                          callback: function ($$v) {
                            _vm.contribBetMin = _vm._n($$v)
                          },
                          expression: "contribBetMin",
                        },
                      })
                    : _vm._e(),
                  _vm.contribTypeOfGroup == _vm.CONTRIB_TYPES.BET
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "Contrib Bet Max",
                          type: "number",
                          suffix: "in credits",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.nonNegative,
                            _vm.rules.greaterThanContribBetMin,
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c("tooltip", {
                                    attrs: {
                                      text: _vm.tooltips.contribBetRange,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2190931805
                        ),
                        model: {
                          value: _vm.form.contrib_bet_range[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.form.contrib_bet_range, 1, _vm._n($$v))
                          },
                          expression: "form.contrib_bet_range[1]",
                        },
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Min Value",
                      type: "number",
                      suffix: "in credits",
                      rules: [_vm.rules.required, _vm.rules.nonNegative],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.minValue },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.min_value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "min_value", _vm._n($$v))
                      },
                      expression: "form.min_value",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Max Value",
                      type: "number",
                      suffix: "in credits",
                      rules: [
                        _vm.rules.required,
                        _vm.rules.nonNegative,
                        _vm.rules.greaterThanMinValue,
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.maxValue },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.max_value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "max_value", _vm._n($$v))
                      },
                      expression: "form.max_value",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Min Bonus",
                      type: "number",
                      suffix: "in credits",
                      rules: [
                        _vm.rules.required,
                        _vm.rules.nonNegative,
                        _vm.rules.betweenMinValueMaxValue,
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.minBonus },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.min_jack,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "min_jack", _vm._n($$v))
                      },
                      expression: "form.min_jack",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mx-3",
                    attrs: {
                      items: _vm.jackpotTypeDropdown,
                      "item-value": "id",
                      "item-text": "name",
                      label: "Select Joker Bonus Type",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.form.jackpot_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jackpot_type_id", $$v)
                      },
                      expression: "form.jackpot_type_id",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mx-3",
                    attrs: {
                      items: _vm.distributionDropdown,
                      "item-value": "id",
                      "item-text": "name",
                      label: "Select Distribution",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.form.distribution_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "distribution_id", $$v)
                      },
                      expression: "form.distribution_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12", md: "6" } },
                [
                  _vm.form.distribution_id == _vm.DISTRIBUTION_TYPES.BETA
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "Mean",
                          suffix: "in credits",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.decimal,
                            _vm.rules.betweenMinBonusMaxValue,
                          ],
                        },
                        model: {
                          value: _vm.form.mean,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mean", _vm._n($$v))
                          },
                          expression: "form.mean",
                        },
                      })
                    : _vm._e(),
                  _vm.form.distribution_id == _vm.DISTRIBUTION_TYPES.BETA
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "Variance",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.validateVariance,
                          ],
                        },
                        model: {
                          value: _vm.form.variance,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "variance", _vm._n($$v))
                          },
                          expression: "form.variance",
                        },
                      })
                    : _vm._e(),
                  _vm.form.distribution_id == _vm.DISTRIBUTION_TYPES.K_UNIFORM
                    ? _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          label: "K",
                          rules: [_vm.rules.required, _vm.rules.decimal],
                        },
                        model: {
                          value: _vm.form.k,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "k", _vm._n($$v))
                          },
                          expression: "form.k",
                        },
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Count",
                      type: "number",
                      rules: [
                        _vm.rules.required,
                        _vm.rules.minMaxValue(0, 100),
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.count },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "count", _vm._n($$v))
                      },
                      expression: "form.count",
                    },
                  }),
                  _c("v-checkbox", {
                    ref: "specifyCheckbox",
                    staticClass: "pr-auto pl-auto mx-3",
                    attrs: {
                      label: "Specify shares distribution",
                      disabled: _vm.form.count < 2,
                    },
                    model: {
                      value: _vm.specifyShares,
                      callback: function ($$v) {
                        _vm.specifyShares = $$v
                      },
                      expression: "specifyShares",
                    },
                  }),
                  _vm.specifyShares
                    ? _c(
                        "div",
                        { staticClass: "col-12 pl-3 pr-1" },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.form.shares, function (share, i) {
                              return _c("v-text-field", {
                                key: i,
                                staticClass: "col-3",
                                attrs: {
                                  type: "number",
                                  suffix: "%",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.minMaxValue(0, 100),
                                  ],
                                },
                                model: {
                                  value: _vm.form.shares[i],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.shares, i, _vm._n($$v))
                                  },
                                  expression: "form.shares[i]",
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-text-field", {
                                staticClass: "col-12",
                                attrs: {
                                  label: "Sum",
                                  readonly: "",
                                  "read-only": "",
                                  suffix: "%",
                                  rules: [_vm.rules.sharesAddUp],
                                },
                                model: {
                                  value: _vm.sharesSum,
                                  callback: function ($$v) {
                                    _vm.sharesSum = $$v
                                  },
                                  expression: "sharesSum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-select", {
                    staticClass: "mx-3",
                    attrs: {
                      items: _vm.selectionDropdown,
                      "item-value": "id",
                      "item-text": "name",
                      label: "Specify Selection",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.form.selection_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "selection_id", $$v)
                      },
                      expression: "form.selection_id",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mx-3",
                    attrs: {
                      items: _vm.candidatesDropdown,
                      "item-value": "id",
                      "item-text": "name",
                      label: "Select Candidates",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.form.candidates_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "candidates_id", $$v)
                      },
                      expression: "form.candidates_id",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mx-3",
                    attrs: {
                      items: _vm.probabilityDropdown,
                      "item-value": "id",
                      "item-text": "name",
                      label: "Select Probability",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.form.probability_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "probability_id", $$v)
                      },
                      expression: "form.probability_id",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Trim",
                      suffix: "%",
                      type: "number",
                      rules: [
                        _vm.rules.type("number"),
                        _vm.rules.minMaxValue(0, 100, {
                          inclusiveRight: false,
                        }),
                        _vm.rules.required,
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.trim },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.trim,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "trim", _vm._n($$v))
                      },
                      expression: "form.trim",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Minimum Contribution",
                      type: "number",
                      rules: [_vm.rules.required, _vm.rules.minMaxValue(0)],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.minContribution },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.min_contribution,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "min_contribution", _vm._n($$v))
                      },
                      expression: "form.min_contribution",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mx-3",
                    attrs: {
                      label: "Active Time",
                      type: "number",
                      suffix: "seconds",
                      rules: [_vm.rules.required, _vm.rules.minMaxValue(0)],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("tooltip", {
                              attrs: { text: _vm.tooltips.activeTime },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.active_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "active_time", _vm._n($$v))
                      },
                      expression: "form.active_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }