<template>
	<v-tooltip
		:color="color"
		bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-icon
				class="pl-2"
				color="primary"
				dark
				v-bind="attrs"
				v-on="on"
				:size="iconSize">
				mdi-help-circle
			</v-icon>
		</template>
		<span>{{ text }}</span>
	</v-tooltip>
</template>

<script>
import { format } from "date-fns";

export default {
	props: {
		text: {
			type: String,
			default: undefined,
		},
		iconSize: {
			type: String,
			default: "18px",
		},
		color: {
			type: String,
			default: "secondary",
		},
	},
};
</script>
