<template>
	<v-form
		ref="form"
		v-model="valid"
		:disabled="disableForm">
		<div
			class="d-flex flex-wrap flex-column align-content-start text-subtitle-1"
			no-gutters>
			<v-row style="width: 100%">
				<v-col
					class="pa-0 ma-0"
					cols="12"
					md="6">
					<v-text-field
						label="Config Name"
						v-model="form.name"
						:rules="[
							rules.required,
							rules.no_spaces,
							rules.minMaxLength(3, 42),
						]"
						class="text-center mx-3" />
					<v-text-field
						v-if="contribTypeOfGroup == CONTRIB_TYPES.PERCENT"
						label="Contrib Ratio"
						v-model.number="form.contrib_ratio"
						suffix="%"
						type="number"
						step="0.1"
						:rules="[rules.required, rules.minMaxValue(0, 100)]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip
								:iconSize="'18px'"
								:text="tooltips.contribRatio" />
						</template>
					</v-text-field>
					<v-text-field
						v-if="contribTypeOfGroup == CONTRIB_TYPES.BET"
						label="Contrib Bet Min"
						type="number"
						suffix="in credits"
						v-model.number="contribBetMin"
						:rules="[rules.required, rules.nonNegative]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.contribBetRange" />
						</template>
					</v-text-field>
					<v-text-field
						v-if="contribTypeOfGroup == CONTRIB_TYPES.BET"
						label="Contrib Bet Max"
						type="number"
						suffix="in credits"
						v-model.number="form.contrib_bet_range[1]"
						:rules="[
							rules.required,
							rules.nonNegative,
							rules.greaterThanContribBetMin,
						]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.contribBetRange" />
						</template>
					</v-text-field>
					<v-text-field
						label="Min Value"
						type="number"
						suffix="in credits"
						v-model.number="form.min_value"
						:rules="[rules.required, rules.nonNegative]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.minValue" />
						</template>
					</v-text-field>
					<v-text-field
						label="Max Value"
						type="number"
						suffix="in credits"
						v-model.number="form.max_value"
						:rules="[
							rules.required,
							rules.nonNegative,
							rules.greaterThanMinValue,
						]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.maxValue" />
						</template>
					</v-text-field>
					<v-text-field
						label="Min Bonus"
						type="number"
						suffix="in credits"
						v-model.number="form.min_jack"
						:rules="[
							rules.required,
							rules.nonNegative,
							rules.betweenMinValueMaxValue,
						]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.minBonus" />
						</template>
					</v-text-field>
					<v-select
						:items="jackpotTypeDropdown"
						v-model="form.jackpot_type_id"
						item-value="id"
						item-text="name"
						label="Select Joker Bonus Type"
						:rules="[rules.required]"
						class="mx-3" />
					<v-select
						:items="distributionDropdown"
						v-model="form.distribution_id"
						item-value="id"
						item-text="name"
						label="Select Distribution"
						:rules="[rules.required]"
						class="mx-3" />
				</v-col>
				<v-col
					class="pa-0 ma-0"
					cols="12"
					md="6">
					<v-text-field
						v-if="form.distribution_id == DISTRIBUTION_TYPES.BETA"
						label="Mean"
						suffix="in credits"
						v-model.number="form.mean"
						:rules="[
							rules.required,
							rules.decimal,
							rules.betweenMinBonusMaxValue,
						]"
						class="mx-3" />
					<v-text-field
						v-if="form.distribution_id == DISTRIBUTION_TYPES.BETA"
						label="Variance"
						v-model.number="form.variance"
						:rules="[rules.required, rules.validateVariance]"
						class="mx-3" />
					<v-text-field
						v-if="form.distribution_id == DISTRIBUTION_TYPES.K_UNIFORM"
						label="K"
						v-model.number="form.k"
						:rules="[rules.required, rules.decimal]"
						class="mx-3" />
					<v-text-field
						label="Count"
						type="number"
						v-model.number="form.count"
						:rules="[rules.required, rules.minMaxValue(0, 100)]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.count" />
						</template>
					</v-text-field>
					<v-checkbox
						class="pr-auto pl-auto mx-3"
						ref="specifyCheckbox"
						v-model="specifyShares"
						label="Specify shares distribution"
						:disabled="form.count < 2" />
					<div
						v-if="specifyShares"
						class="col-12 pl-3 pr-1">
						<v-row>
							<v-text-field
								v-for="(share, i) in form.shares"
								:key="i"
								type="number"
								suffix="%"
								v-model.number="form.shares[i]"
								:rules="[rules.required, rules.minMaxValue(0, 100)]"
								class="col-3" />
						</v-row>
						<v-row justify="center">
							<v-text-field
								class="col-12"
								label="Sum"
								readonly
								read-only
								suffix="%"
								v-model="sharesSum"
								:rules="[rules.sharesAddUp]" />
						</v-row>
						<v-divider />
					</div>
					<v-select
						:items="selectionDropdown"
						v-model="form.selection_id"
						item-value="id"
						item-text="name"
						label="Specify Selection"
						:rules="[rules.required]"
						class="mx-3" />
					<v-select
						:items="candidatesDropdown"
						v-model="form.candidates_id"
						item-value="id"
						item-text="name"
						label="Select Candidates"
						class="mx-3"
						:rules="[rules.required]" />
					<v-select
						:items="probabilityDropdown"
						v-model="form.probability_id"
						item-value="id"
						item-text="name"
						label="Select Probability"
						:rules="[rules.required]"
						class="mx-3" />
					<v-text-field
						label="Trim"
						v-model.number="form.trim"
						suffix="%"
						type="number"
						:rules="[
							rules.type('number'),
							rules.minMaxValue(0, 100, { inclusiveRight: false }),
							rules.required,
						]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.trim" />
						</template>
					</v-text-field>
					<v-text-field
						label="Minimum Contribution"
						v-model.number="form.min_contribution"
						type="number"
						:rules="[rules.required, rules.minMaxValue(0)]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.minContribution" />
						</template>
					</v-text-field>
					<v-text-field
						label="Active Time"
						type="number"
						v-model.number="form.active_time"
						suffix="seconds"
						:rules="[rules.required, rules.minMaxValue(0)]"
						class="mx-3">
						<template v-slot:append-outer>
							<tooltip :text="tooltips.activeTime" />
						</template>
					</v-text-field>
				</v-col>
			</v-row>
		</div>
	</v-form>
</template>

<script>
import { rules as defaultRules } from "../../../plugins/rules.ts";
import Tooltip from "../../shared/LoganTooltip";
import { CONTRIB_TYPES } from "../../../constants/contribTypes";
import { DISTRIBUTION_TYPES } from "../../../constants/distributions";

export default {
	components: {
		Tooltip,
	},

	props: {
		value: {
			type: Object,
		},
		contribTypeOfGroup: {
			type: String,
			default: "",
		},
		disableForm: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			valid: false,
			CONTRIB_TYPES,
			DISTRIBUTION_TYPES,

			/** ^^^^ */
			jackpotTypeDropdown: [
				{ id: 1, name: "Predetermined" },
				{ id: 2, name: "Probabilistic" },
			],
			distributionDropdown: [
				{ id: 1, name: "Beta" },
				{ id: 2, name: "K-Uniform" },
			],
			selectionDropdown: [
				{ id: 1, name: "Random" },
				{ id: 2, name: "Last" },
			],
			candidatesDropdown: [
				{ id: 1, name: "All" },
				{ id: 2, name: "Contributed" },
			],
			probabilityDropdown: [
				{ id: 1, name: "Even" },
				{ id: 2, name: "Weighted" },
			],
			contribTypeDropdown: [
				{ id: 1, name: "Percentage" },
				{ id: 2, name: "Bet" },
			],
			//^^^^
			specifyShares_: false,
			rules: {
				...defaultRules,
				greaterThanContribBetMin: (v) =>
					v >= this.contribBetMin || "Must be greater than Contrib Bet Min",
				betweenMinValueMaxValue: (v) =>
					(v >= this.form.min_value && v <= this.form.max_value) ||
					"Must be between Min value and Max value",
				betweenMinBonusMaxValue: (v) =>
					(v >= this.form.min_jack && v <= this.form.max_value) ||
					"Must be between Min Bonus and Max value",
				validateVariance: (v) =>
					(v > 0 && v < this.varianceThreshold) ||
					"Must be between 0 and " + this.varianceThreshold,
				greaterThanMinValue: (v) =>
					v >= this.form.min_value || "Must be greater or equal to Min Value",
				sharesAddUp: (v) =>
					this.sharesSum == 100 || "Sum of the shares must be 100%",
			},

			tooltips: {
				contribRatio: "Percentage of group contribution added to this level.",
				contribBetRange:
					"in credits If the bet is in the contrib bet min and contrib bet max range, whole group contribution is added to this level. ",
				minValue: "in credits. Minimum value of Bonus. ",
				minBonus:
					"in credits. Minimum value, at which the Bonus can be awarded.",
				maxValue: "in credits. Maximum value of Bonus. ",
				count: "Number of winners for this Bonus. max value is 100",
				trim: "Percentage of lowest contributing player not eligible for win.",
				minContribution:
					"Minimum value, that a player must contribute to be eligible.",
				activeTime:
					"Time in seconds. Used to determine whether a player is active. Only active players are eligible.",
			},
		};
	},

	computed: {
		form() {
			const res = this.value;
			if (
				this.contribTypeOfGroup === CONTRIB_TYPES.BET &&
				res.contrib_bet_range === undefined
			) {
				res.contrib_bet_range = [0, 100];
			}
			if (
				this.contribTypeOfGroup === CONTRIB_TYPES.PERCENT &&
				res.contrib_ratio === undefined
			) {
				res.contrib_ratio = 0;
			}
			return this.value;
		},
		contribBetMin: {
			get() {
				return this.form.contrib_bet_range?.[0] ?? 0;
			},
			set(newVal) {
				this.form.contrib_bet_range[0] = newVal;
			},
		},
		varianceThreshold() {
			let mu =
				(this.form.mean - this.form.min_jack) /
				(this.form.max_value - this.form.min_jack);
			return mu * (1 - mu);
		},
		sharesSum() {
			return this.form.shares?.reduce((prev, curr) => prev + curr, 0);
		},
		specifyShares: {
			get() {
				return this.specifyShares_;
			},
			set(newVal) {
				if (!newVal) {
					this.form.shares = null;
					this.specifyShares_ = false;
				} else {
					this.fillShares();
				}
			},
		},
	},

	methods: {
		fillShares() {
			if (this.form.count === null || this.form.count === undefined) return;
			const newShares = new Array();
			newShares.length = this.form.count;
			newShares.fill(100 / this.form.count);
			this.$set(this.form, "shares", newShares);
			// this.form.shares = newShares;
			if (this.form.shares?.length > 1) {
				this.specifyShares_ = true;
			} else {
				this.specifyShares_ = false;
			}
		},
	},

	watch: {
		form: {
			async handler(newVal) {
				await this.$nextTick();
				if (this.contribTypeOfGroup === CONTRIB_TYPES.PERCENT) {
					delete newVal.contrib_bet_range;
				}
				if (this.contribTypeOfGroup === CONTRIB_TYPES.BET) {
					delete newVal.contrib_ratio;
				}
				const valid = this.$refs.form.validate();
				this.$emit("input", [newVal, valid]);
			},
			deep: true,
		},
		"form.count"(newVal) {
			if (
				this.$refs.specifyCheckbox.internalValue &&
				this.form.shares?.length !== newVal
			) {
				this.fillShares();
			}
		},
		"form.shares"(newVal) {
			if (newVal?.length > 1) {
				this.specifyShares_ = true;
				return;
			}
			this.specifyShares_ = false;
		},
	},
};
</script>
