var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { color: _vm.color, bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "pl-2",
                      attrs: { color: "primary", dark: "", size: _vm.iconSize },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" mdi-help-circle ")]
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }